import "../css/global.scss"
import {BackButton} from "../UI/Buttons";

export const Links = () => {
    return (
        <>
            <BackButton path={-1}/>

            <div className="article-container-new">
                <div className="article center links ">
                    <h2>
                        Links
                    </h2>

                    <p>
                        <h3 style={{ textAlign:"start"}}> IBFed Biodanza Schulen in Deutschland</h3>

                        <ul>
                            <li><a
                                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjH5Iq6xL2EAxVN0QIHHf9dB6kQFnoECBQQAQ&url=https%3A%2F%2Fwww.biodanza-ibf-deutschland.de%2F&usg=AOvVaw36r2KTKOBrMBzy38_QFKli&opi=89978449" rel="noreferrer" target="_blank">
                                <h4>Dachverband Deutscher Biodanza Schulen (IBFed) - GDBS </h4></a></li>

                            <li><a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjk6buGw72EAxUB0QIHHVD-CnwQFnoECCAQAQ&url=https%3A%2F%2Fwww.biodanza-online.de%2Fpages%2Fde%2Fhome.php&usg=AOvVaw2N-OuEPLqhvXInHNs_oASG&opi=89978449" rel="noreferrer" target="_blank"><h4>
                                Biodanza Schule Köln
                            </h4></a></li>

                            <li><a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwj6voytw72EAxUp9gIHHQUZBvUQFnoECB0QAQ&url=https%3A%2F%2Fwww.biodanza-muenster.de%2Fhome%2F&usg=AOvVaw2VqnB8C6WbYiTWsqD4tJNa&opi=89978449" rel="noreferrer" target="_blank"><h4>
                                Biodanza Schule Münster
                            </h4></a></li>

                            <li><a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwj6voytw72EAxUp9gIHHQUZBvUQFnoECB0QAQ&url=https%3A%2F%2Fwww.biodanza-muenster.de%2Fhome%2F&usg=AOvVaw2VqnB8C6WbYiTWsqD4tJNa&opi=89978449" rel="noreferrer" target="_blank"><h4>
                                Biodanza Schulle Leipzig
                            </h4></a></li>

                            <li><a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwj4rIvLw72EAxW39AIHHeNcBFoQFnoECBYQAQ&url=https%3A%2F%2Fars-magna.de%2Fbiodanza%2F&usg=AOvVaw3jLl9PB4aUD5R2wJP18CjU&opi=89978449" rel="noreferrer" target="_blank"><h4>
                                Biodanza Schule Stuttgart Reutlingen
                            </h4></a></li>

                            <li><a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwje9fbtw72EAxXY_AIHHd0jBPkQFnoECBQQAQ&url=https%3A%2F%2Fbiodanza-in-berlin.com%2F&usg=AOvVaw0edRkdqp4cwyYiLnqMmhNQ&opi=89978449" rel="noreferrer" target="_blank"><h4>
                                Biodanza Schule Berlin
                            </h4></a></li>

                        </ul>



                        <h3 style={{ textAlign:"start"}}> Biodanza rund um Freiburg</h3>

                        <ul>
                            <li><a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwi35tDQwb2EAxX90AIHHe_7Ca0QFnoECBIQAQ&url=https%3A%2F%2Fbiodanza-konstanz.de%2Fueber-mich%2F&usg=AOvVaw2_t2DUPMMLVC-5OkCIkmyV&opi=89978449" rel="noreferrer" target="_blank">
                                <h4>
                                    Julia Vece Vogelsang
                                </h4>
                            </a></li>

                            <li><a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwit87e-wb2EAxVezAIHHcsaCZoQFnoECAgQAQ&url=https%3A%2F%2Fwww.martina-biodanza.de%2F&usg=AOvVaw0QYS9RRsVs_4lblVVNMAFa&opi=89978449" rel="noreferrer" target="_blank">
                                <h4>
                                    Martina Kaiser
                                </h4>
                            </a></li>

                            <li><a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjc2oaOwb2EAxUL2wIHHdkWCUoQFnoECAkQAQ&url=https%3A%2F%2Fbiodanza-emmendingen.de%2F&usg=AOvVaw0yYZEt8N84zUZEKWkfr9TB&opi=89978449" rel="noreferrer" target="_blank">
                                <h4>
                                    Biodanza Emmendingen
                                </h4>
                            </a></li>

                        </ul>



                        <h3 style={{ textAlign:"start"}}> Biodanza in der Pfalz</h3>

                        <ul>
                            <li><a
                                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwixu4zQwr2EAxXc9gIHHZ1iAmMQFnoECBYQAQ&url=http%3A%2F%2Fwww.biodanza-pfalz-beate.de%2F&usg=AOvVaw0Yff3HAzpSYmASLMQMmAn_&opi=89978449" rel="noreferrer" target="_blank">
                                <h4>
                                    Beate Hoffmann
                                </h4></a></li>

                            <li><a
                                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwicuNDfwr2EAxXt7gIHHQtJDG0QFnoECBIQAQ&url=http%3A%2F%2Fwww.biodanza-rhein-neckar.de%2F&usg=AOvVaw23FmEylqmgurOephmn-W91&opi=89978449" rel="noreferrer" target="_blank">
                                <h4>
                                    Ute Krapf
                                </h4></a></li>

                        </ul>


                        <h3 style={{ textAlign:"start"}}> Biodanza Schulen in Europa: </h3>

                        <ul>
                            <li><a
                                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjH5Iq6xL2EAxVN0QIHHf9dB6kQFnoECBEQAQ&url=https%3A%2F%2Fwww.biodanza.org%2Fen%2Fhome-5%2F&usg=AOvVaw3wt5has0DVEBYIL7v4T5GT&opi=89978449" rel="noreferrer" target="_blank">
                                <h4>
                                    Internationaler Dachverband Biodanza IBFed

                                </h4></a></li>

                            <li><a
                                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiQz4yLwr2EAxXfxAIHHW2hAGQQFnoECA4QAQ&url=https%3A%2F%2Fwww.torobiodanza.be%2F&usg=AOvVaw09xQmF_2qNUoP-v80dOtfw&opi=89978449" rel="noreferrer" target="_blank">
                                <h4>
                                    Biodanza Schule Lüttich 4 Ardennen

                                </h4></a></li>

                            <li><a
                                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwj4q9Pgwb2EAxXMxgIHHZb4AzwQFnoECA8QAQ&url=https%3A%2F%2Fwww.biodanzatoro.com%2F&usg=AOvVaw2T5hf00xpwNGT42Y9ryiAi&opi=89978449" rel="noreferrer" target="_blank">
                                <h4>
                                    Biodanza Schule Carcassonne Pays Cathare

                                </h4></a></li>

                            <li><a
                                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwit3ovyxb2EAxX_xQIHHQW4BnMQFnoECBAQAQ&url=https%3A%2F%2Fbiodanza-federation-france.com%2Fnode%2F108&usg=AOvVaw0bBiw_tN4oWIUgtVlcXgBe&opi=89978449" rel="noreferrer" target="_blank">
                                <h4>
                                    Biodanza Schule Toulouse

                                </h4></a></li>

                            <li><a
                                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjGzeOdxr2EAxUE9wIHHTO7CZAQFnoECA4QAQ&url=https%3A%2F%2Fwww.ecolebiodanzabordeaux.fr%2F&usg=AOvVaw3qyH45M08AAdUFETTj3Zo1&opi=89978449" rel="noreferrer" target="_blank">
                                <h4>
                                    Biodanza Schule Bordeaux

                                </h4></a></li>

                            <li><a
                                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjc-tCoxb2EAxXy0AIHHYD9ANIQFnoECBIQAQ&url=https%3A%2F%2Fwww.ecolebiodanza-med.com%2F&usg=AOvVaw1Zz7HeY6QXLC6l3MSEXVbm&opi=89978449" rel="noreferrer" target="_blank">
                                <h4>
                                    Biodanza Schule Mittelmeer

                                </h4></a></li>

                            <li><a
                                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwi-1qXYxb2EAxWw3AIHHVumAgQQFnoECB0QAQ&url=https%3A%2F%2Fwww.ecolebiodanza-grandparis.fr%2F&usg=AOvVaw0ioRFztIXobl5fBDKBY-UA&opi=89978449" rel="noreferrer" target="_blank">
                                <h4>
                                    Biodanza Schule Großraum Paris

                                </h4></a></li>

                            <li><a
                                href="https://melaniedelval.at/" rel="noreferrer" target="_blank">
                                <h4>
                                    Wiener Biodanza Schule
                                </h4></a></li>

                            <li><a
                                href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&ved=2ahUKEwjhy5DExr2EAxXq8wIHHc4AAzEQFnoECA0QAQ&url=https%3A%2F%2Fwww.ecolebiodanzageneve.ch%2F&usg=AOvVaw3IzQCOkn3AWr65SBOBICHQ&opi=89978449" rel="noreferrer" target="_blank">
                                <h4>
                                    Genfer Biodanza Schule

                                </h4></a></li>


                        </ul>

                    </p>


                </div>
            </div>
        </>
    )
}
