import "../css/article.scss"
import { BackButton } from "../UI/Buttons";

export const Ausbildungskalender = () => {
    return (
        <>
            <BackButton path={-1} />

            <div className="article-container-new">
                <div className="article center">


                    <h2>
                        Ausbildungsprogramm
                        2024 - 2027
                    </h2>

                           <p> <b> Du kannst Deinen Ausbildungsstart selbst wählen, da es sich um eine zyklische Ausbildung handelt und Du Deine Ausbildungsmodule im nächsten Ausbildungszyklus einfach vervollständigen kannst. Nimm gerne Kontakt mit uns auf, um Dich zu informieren.  </b> </p>

                    <h3 style={{ fontSize: "26px", margin: "15px 0" }}>
                        Erstes Ausbildungsjahr<br /> (Grundkenntnisse)
                    </h3>

                    <p>
                        <h4>11.-13.10.2024 (Beate Hoffmann - Direktorin der Biodanza Schule Freiburg)</h4>
                        <h5> 1. Definition und theoretisches Modell von Biodanza</h5>
                        Die Geschichte und Grundprinzipien von Biodanza - Biodanza im Kontext der Therapien
                        Entwicklung und Struktur des theoretischen Modells in Biodanza - Der Begriff der Regression im
                        theoretischen Modell
                    </p>

                    <p>
                        <h4>08.-10.11.2024 (Véronique Hustin, Belgien - Direktorin der Biodanza Schule Carcassonne Pays Cathare)</h4>
                        <h5> 2. Das biozentrische Prinzip und das vitale Unbewusste</h5>
                        Die Heiligkeit des Lebens - Reflexionen über unsere Kultur - Der Einfluss von Musik
                        Der Mensch als Hologramm - Das vitale Unbewusste und der endogene Humor
                    </p>

                    <p>
                        <h4>06.-08.12.2024 (Beate Hoffmann - Direktorin der Biodanza Schule Freiburg)</h4>
                        <h5>3. Das Erleben (Vivencia)</h5>
                        Die Bedeutung des Erlebens in Biodanza - Die fünf Erlebnislinien - Beziehung zwischen Erleben,
                        Verhalten und Erfahrung
                    </p>

                    <p>
                        <h4>10.-12.01.2025 (Martine Henkart, Belgien - Didakta)</h4>
                        <h5> 4. Biologische Aspekte von Biodanza</h5>
                        Universale Prinzipien des Lebens - Autoorganisation. Konzept der Autopoiesis - Neue Ideen über
                        Evolution
                    </p>

                    <p>
                        <h4>14.-16.02.2025 (Kety Bétes, Spanien - Didakta) </h4>
                        <h5> 5. Physiologische Aspekte von Biodanza</h5>
                        Das integrative lymbisch-hypothalamische System - Neurovegetatives Nervensystem
                        Immunsystem - Wirkungen von Biodanza auf Nervensystem, endokrines System und Immunsystem
                    </p>

                    <p>
                        <h4>14.-16.03.2025 (Luis Miguel Alfaro, Portugal - Didakta)</h4>
                        <h5> 6. Psychologische Aspekte von Biodanza</h5>
                        Psychologische Vorläufer von Biodanza - Theorie der Instinkte - Erfahrungen über Emotionen und
                        Ausdruck - Neurophysiologie und Psychologie der Emotionen
                    </p>

                    <p><h4>11.-13.04.2025 (Kety Bétes, Spanien - Didakta)  </h4>
                              <h5> 7. Identität und Integration</h5>
                        Verschiedene Konzepte von Identität - Selbstwertgefühl und Selbstbild - Pathologische Identität -
                        Identität und Musik - Biodanza, Erweiterung der Identität
                            
                    </p>
                    <p><h4>09.-11.05.2025 (Marie-Pierre Leplomb, Frankreich - Didakta)</h4>
                        <h5> 8. Mythische und philosophische Hintergründe von Biodanza</h5>
                        Mythen und Archetypen in Biodanza - Mythos von Tod und Auferstehung - Das Denken von Heraklit
                    </p>
                    <p><h4>13.-15.06.2025 (Dario Fatta, Argentinien - Direktor der Biodanza Schule Rosario)</h4>
                        <h5> 9. Trance und Regression</h5>
                        Konzept von Trance und Regression - Verschiedene Formen von Trance - Übungen auf verschiede-nen
                        Trance-Ebenen in Biodanza
                    </p>
                    <p><h4>19.-21.09.2025 (Horacio Lopes, Portugal - Direktor der Biodanza Schule Grand Paris)</h4>
                        <h5> 10. Berührung und Zärtlichkeit</h5>
                        Die Bedeutung von Berührung für den Menschen - Annäherung und Berührung in Feedback
                        Die integrative Kraft von Zärtlichkeit - Grundlagen der Therapien mittels Berührung
                    </p>

                    <h3 style={{ fontSize: "26px", margin: "15px 0" }}>
                        Zweites Ausbildungsjahr <br />
                        (Vertiefung Persönlichkeitsentwicklung)
                    </h3>
                    <p><h4>10.-12.10.2025 (Kety Bétes, Spanien - Didakta) </h4>
                        <h5> 11. Kreativität</h5>
                        Existenzielle Kreativität - Künstlerischer Ausdruck - Biodanza und Malerei, Poesie, Ausdruck in Ton
                        - Kreative Tänze - Kreativität: Pathologien und Interventionen
                    </p>
                    <p><h4> 14.-16.11.2025 (Renate Fleck Garcia und Jan Obrtel, Deutschland - Didakta)</h4>
                        <h5> 12. Die menschliche Bewegung</h5>
                        Systemisches Modell von Bewegung - organischer Rhythmus und Motorik - Psychodiagnose der Bewegung -
                        Der Körper als existenzieller Ausdruck

                    </p>
                    <p><h4>05.-07.12.2025</h4>
                        <h5> 13. Vitalität</h5>
                        Grundlagen der Vitalität; Atmung, Blutdruck, Temperaturregulation, immunologische Antwort
                        Wechselwirkungen zwischen Immunsystem, endokrinem System und Nervensystem
                        Eine integrierte Sicht von Vitalität
                    </p>
                    <p><h4>09.-11.01.2026</h4>
                        <h5> 14. Transzendenz</h5>
                        Konzept von Transzendenz und Regression - Einleitung von Trance in Biodanza - Stadien erweiterten
                        Bewusstseins - Zugang zur Ekstase
                    </p>
                    <p><h4>13.-15.02.2026</h4>
                        <h5> 15. Sexualität</h5>
                        Sexualität und Identität - Undifferenzierter Eros: Liebe zur Welt - Mythische und anthropologische
                        Aspekte der Sexualität - Die Bedeutung kultureller unterdrückender Werte
                    </p>

                    <p><h4>13.-15.03.2026</h4>
                        <h5> 16. Affektivität</h5>
                        Biologische Aspekte der Affektivität - Affektivität und Identität, Bewusstsein, Kommunikation -
                        Affektivität, die biokosmische Intelligenz - Die Bedeutung der Umarmung
                        Glück und Leiden
                    </p>

                    <p><h4>10.-12.04.2026</h4>
                        <h5> 17. Wirkungsweise von Biodanza</h5>
                        Ausdruck der Identität - Neurophysiologische Wirkungen - Wirkungen auf die fünf Ausdruckslinien -
                        Therapeutische Wirkungen und existenzielle Rehabilitation
                    </p>

                    <p><h4>08.-10.05.2026</h4>
                        <h5> 18. Anwendungen und Erweiterungen von Biodanza</h5>
                        Unterschied zwischen Anwendung und Erweiterung - Bedeutendste Anwendungen: Klinisches Bio-danza,
                        biozentrische Erziehung, Biodanza in Organisationen, Biodanza im Wasser, Biodanza für alte Menschen,
                        Biodanza mit Kindern und Jugendlichen, Biodanza mit speziellen Gruppen (Paare, Schwangere, Familien)
                        - Bedeutendste Erweiterungenn: Minotaurus Projekt, Der Baum der Wünsche, Aquabiodanza, Biodanza und
                        Tonerde, Identität und die vier Elemente der Natur, Argonaut Mission, Biodanza und Massage, Biodanza
                        und Erziehung zum Kontakt, Biodanza und Neo-Schamanismus, Der Kreis der Archetypen und die Vorahnung
                        des Engels, Tanz des I-Ging, Biodanza und Tarot, Pro-jekt Evolution - Konzepte der Rehabilitation
                        mit Biodanza
                    </p>
                    <p><h4>12.-14.06.2026</h4>
                        <h5> 19. Biodanza Ars Magna</h5>
                        Grundlagen der psychosomatischen Medizin - Krankheiten der Zivilisation - Wirkungen von Stress -
                        Therapeutische Wirkung von Biodanza
                    </p>
                    <p><h4>18.-20.09.2026</h4>
                        <h5> 20. Biodanza und soziale Aktion</h5>
                        Biodanza und soziale Praxis - Analyse der Pathologie unserer Zivilisation - Dissoziierte Kultur und
                        biozentrische Kultur - Autoren, deren Beziehung mit der biozentrischen Kultur essenziell gewesen
                        ist.
                    </p>

                    <h3 style={{ fontSize: "26px", margin: "15px 0" }}>
                        Drittes Ausbildungsjahr<br />
                        (Professionalisierung)
                    </h3>
                    <p><h4>09.-11.10.2026</h4>
                        <h5> 21. Musik in Biodanza</h5>
                        Vorgänger - Grundlegende Parameter der Musik: Rhythmus, Melodie, Harmonie und Tonalität - Ebe-nen
                        musikalischer Erfahrung: organische und anorganische Musik - Organische Musik: Wirkungen von Musik
                        und Tanz auf den menschlichen Körper
                        Einführung in verschiedene Musikarten
                    </p>
                    <p><h4>13.-15.11.2026</h4>
                        <h5> 22. Methodologie I: Musikalische Semantik</h5>
                        Musikalische Semantik (Bedeutung der Musik) - Auswahlkriterien für Musik in Biodanza -
                        Praktische Beispiele und Übungen zur Anleitung von Tänzen
                    </p>
                    <p><h4>04.-06.12.2026</h4>
                        <h5> 23. Minotaurusprozess</h5>
                        Ein Wochenende zur Identitätsentwicklung durch Bewältigung der persönlichen Grenzen.
                    </p>
                    <p><h4>15.-17.01.2027</h4>
                        <h5> 24. Methodologie VII: Offizielle Liste der Biodanza Übungen</h5>
                        Die Notwendigkeit eine offizielle Liste der Übungen, Präsentationen und Musik zu haben
                        Orthodoxie und systematischer Zusammenhang - Unterschiedliche Übungen und Anwendungsbei-spiele -
                        Intellektuelles Eigentum von Rolando Toro Araneda
                    </p>
                    <p><h4>12.-14.02.2027</h4>
                        <h5> 25. Methodologie II: Die Biodanza Session: Teil 1</h5>
                        Ziele, Aufbau und Dauer einer Biodanza Vivencia - Physiologische, poetische und strukturelle
                        As-pekte einer Übung - Praktische Beispiele und Übungen zur Planung und Durchführung
                    </p>
                    <p><h4> 12.-14.03.2027</h4>
                        <h5> 26. Methodologie III: Die Biodanza Session: Teil 2</h5>
                        Biodanza Vivencia für Anfänger - Vertiefung des Erlebens (Fortgeschrittene) - Schwierigkeiten und
                        methodische Fehler - Erfahrung als Biodanza-Lehrer/in
                    </p>
                    <p><h4>09.-11.04.2027</h4>
                        <h5> 27. Methodologie IV: Wochengruppe und Workshops</h5>
                        Ziele und Ablauf von wöchentlichen Gruppen - Theoretische und praktische Anteile der Session -
                        Struktur und Aufbau der Vivencias am Wochenende
                    </p>
                    <p><h4> 21.-23.05.2027</h4>
                        <h5> 28. Methodologie V: Die Gruppe in Biodanza</h5>
                        Die Integration der Gruppe - Funktionen der Gruppe - Haltung des/r Biodanza-Lehrer/in in der
                        Ses-sion - Mikro-Welt der Gruppe und Makro-Welt des sozialen Lebens
                    </p>

                    <p><h4> 11.-13.06.2027</h4>
                        <h5> 29. Methodologie VI: Entwicklungsktiterien</h5>
                        Programm für Anfänger und Fortgeschrittene
                        Theorie und Praxisanteile in den jeweiligen Gruppen
                        Praktische Beispiele und Übungen zu allen Themen
                    </p>

                    <p><h4>09.-11.07.2027</h4>
                        <h5> 30. Methodik Seminar (Praxis der Methode) </h5>
                        Die Auswahl der Themen wird gemeinsam zwischen der Direktorin und der Ausbildungsgruppe ge-troffen.
                        Die Vivencias werden von den Studenten vorbereitet.
                    </p>


                </div>
            </div>
        </>
    )
}
